import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import prettyName from "pretty-name";
import video from "assets/video.png";
import link from "assets/link.png";
import {
  courseDetails,
  courseLink,
  loading,
  SingleCourse,
} from "../../redux/coures/courseAction";
import { useNavigate } from "react-router-dom";
import { getAllUserCourses, updateCourse } from "Axios/apis";
import tick from "assets/accept.png";
export const ChapterItem = ({
  content,
  height,
  chapter,
  section_id,
  courseID,
  progressCircle,
  live,
}) => {
  const token = localStorage.getItem("token");
  const InterviewProRole = localStorage.getItem("role");
  const [newurl, setnewurl] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log("ChapterItem Component Rendered");
  console.log("Chapter:", chapter);
  console.log("Section ID:", section_id);
  console.log("Course ID:", courseID);
  console.log("Progress Circle:", progressCircle);
  console.log("Live:", live);

  useEffect(() => {
    if (
      chapter.chapterName === "Interview Pro" &&
      InterviewProRole === "interviewee"
    ) {
      setnewurl(
        "https://interviewpro.viosa.in/interviewee/dashboard" + "/" + token
      );
    } else {
      setnewurl(chapter?.url);
    }
  }, []);
  const courseNavigate = async (link) => {
    dispatch(
      courseLink({
        link2: link,
        chapter_id: chapter._id || chapter.chapter_id,
        course_id: courseID,
      })
    );
    dispatch(loading(true));
    const courseBody = {
      course_id: courseID,
      section_id: section_id,
      chapter_id: chapter.chapter_id || chapter._id,
    };

    try {
      const courseUpdate = await updateCourse(
        {
          course_id: courseID,
          chapter_id: chapter._id,
          courseCompleted: true,
        },
        token
      );
      const response = await getAllUserCourses(token);
      const singleCourseData = response?.data?.courses.find(
        (c) => c.courseID == courseID
      );
      dispatch(SingleCourse(singleCourseData));
      dispatch(courseDetails(courseBody));
      dispatch(SingleCourse(singleCourseData));
      dispatch(loading(false));
      navigate(
        `/course/play?course_id=+${courseID}+&section_id=+${section_id}+&chapter_id=+${chapter._id}`
      );
    } catch (error) {
      // //console.log(error);
      dispatch(loading(false));
    }
    // window.open(link, '_blank', 'noopener,noreferrer');
  };

  const AssessmentNavigate = () => {
    console.log("AssessmentNavigate function called");
    console.log("Chapter:", chapter);
    console.log("Chapter Name:", chapter.chapterName);
    console.log("Assessment Length:", chapter.assessment.length);
    dispatch(
      courseLink({
        link6: chapter.assessment,
      })
    );
    navigate("/assignment");
  };
  const htmlServe = () => {
    dispatch(
      courseLink({
        link7: chapter.html,
      })
    );
    navigate(
      `/course/play?course_id=" + ${courseID}+ "&section_id=" + ${section_id} + "&chapter_id=" + ${chapter._id}`
    );
  };

  const playVideo = async () => {
    dispatch(
      courseLink({
        link: chapter.videoLink,
        chapter_id: chapter._id || chapter.chapter_id,
        course_id: courseID,
      })
    );
    dispatch(loading(true));
    const courseBody = {
      course_id: courseID,
      section_id: section_id,
      chapter_id: chapter.chapter_id || chapter._id,
    };
    const response = await getAllUserCourses(token);
    const singleCourseData = response?.data?.courses.find(
      (c) => c.courseID == courseID
    );
    dispatch(SingleCourse(singleCourseData));
    dispatch(courseDetails(courseBody));
    singleCourseData &&
      navigate(`/course/play?course_id=${courseID}&chapter_id=${chapter._id}`);
    dispatch(loading(false));
  };

  return (
    <div
      ref={content}
      style={{ maxHeight: `${height}` }}
      className="accordion__content"
    >
      {chapter?.videoLink && (
        <div className="accordion-heading cursor-pointer" onClick={playVideo}>
          {/* <PlayCircleOutlinedIcon /> */}
          <img
            src={video}
            height="20"
            width="20"
            style={{
              marginLeft: "5px",
            }}
          />

          <div
            className="accordion__text"
            dangerouslySetInnerHTML={{
              __html:
                chapter.videoLink &&
                `${prettyName(
                  chapter.name || chapter.chapterName || "Untitle Chapter"
                )}`,
            }}
          />
          {progressCircle?.includes(chapter._id) && (
            <img className="mx-1" width="16" src={tick} alt="" />
          )}
          <hr />
        </div>
      )}
      {chapter?.pdfLink && (
        <div
          className="accordion-heading cursor-pointer"
          style={{}}
          // onClick={() => courseNavigate("pdfLink")}
          onClick={() => window.open(chapter.pdfLink, "_blank")}
        >
          <div
            className="accordion__text"
            dangerouslySetInnerHTML={{
              __html:
                chapter.pdfLink &&
                `Pdf:${prettyName(
                  chapter.name || chapter.chapterName || "Untitle Chapter"
                )}`,
            }}
          />
          {progressCircle?.includes(chapter._id) && (
            <img className="mx-1" width="16" src={tick} alt="" />
          )}
        </div>
      )}
      {chapter?.url && (
        <a
          className="accordion-heading cursor-pointer"
          href={newurl}
          // onClick={() => courseNavigate(chapter?.url)}
        >
          <img
            src={link}
            height="20"
            width="20"
            style={{
              marginLeft: "5px",
            }}
          />
          <div
            className="accordion__text"
            dangerouslySetInnerHTML={{
              __html:
                chapter.url &&
                `${prettyName(
                  chapter.name || chapter.chapterName || "Untitle Chapter"
                )}`,
            }}
          />
          {progressCircle?.includes(chapter._id) && (
            <img className="mx-1" width="16" src={tick} alt="" />
          )}
        </a>
      )}
      {/* {chapter?.assessment.length > 0 && chapter.chapterName && ( */}
      {chapter?.assessment.length > 0 && (
        <div
          className="accordion-heading cursor-pointer"
          onClick={() => AssessmentNavigate()}
        >
          <div
            className="accordion__text"
            dangerouslySetInnerHTML={{
              __html:
                chapter.assessment &&
                `Assessment:${prettyName(chapter.chapterName)}`,
            }}
          />
          {chapter.isCompleted && <img src={tick} alt="" />}
        </div>
      )}
      {chapter?.html && (
        <div
          className="accordion-heading cursor-pointer"
          onClick={() => htmlServe()}
        >
          <div
            className="accordion__text"
            dangerouslySetInnerHTML={{
              __html:
                chapter.html &&
                `Content:${prettyName(
                  chapter.chapterName || "Untitle Chapter"
                )}`,
            }}
          />
        </div>
      )}
      {live?.link && (
        <div
          className="accordion-heading cursor-pointer"
          onClick={() => htmlServe()}
        >
          <div
            className="accordion__text"
            dangerouslySetInnerHTML={{
              __html:
                live.link &&
                `Live Session :${prettyName(live.name || "Live Session")}`,
            }}
          />
        </div>
      )}
    </div>
  );
};
