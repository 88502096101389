import HeaderTop from "components/HeaderTop";
import React, { useEffect, useState } from "react";
import { consellingData } from "./coursellingData";
import {
  GetUserDetails,
  getAllUserCourses,
  pca,
  statusCheck,
  getProgressById,
  PcaStatus,
  getMyCourse,
} from "Axios/apis";
import { careerAssessmentCD } from "./data";
import { Link, useSearchParams } from "react-router-dom";
import uiUx from "../../../icons/placeholder2.png";
import Spinner from "components/Spinner/Spinner";
import { Show } from "components/Show";
import NotEnrolled from "components/NotEnrolled";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addStatus } from "redux/user/userAction";
import axios from "axios";

export const CareerAssessmentTest = ({ headerTop, togglesidebar }) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [searchParams] = useSearchParams();
  const [iframeData, setIframeData] = useState();
  const [selectEnable, setSelectEnable] = useState(false);
  const [userCourse, setUserCourse] = useState([]);
  const [dashboardCourse, setDashboardCourse] = useState();
  const token = localStorage.getItem("token");
  const [careerAssessmentData, setCareerAssessmentData] = useState([]);
  const store = useSelector((state) => state.user);
  const [timer, setTimer] = useState(false);
  const dispatch = useDispatch();
  // State for storing user details
  const [userDetails, setUserDetails] = useState(null);
  const [assessmentStatus, setAssessmentStatus] = useState(null);
  const [isReportGenerated, setIsReportGenerated] = useState(false);
  const [userName, setUserName] = useState(""); // Add state for user's name

  useEffect(() => {
    console.log("Fetching user courses...");
    //   const fetchCourse = async () => {
    //     setLoading2(true);
    //     try {
    //       const response = await getAllUserCourses(token);
    //       const filteredCourses = [
    //         ...new Map(
    //           response?.data?.courses?.map((m) => [m.courseID, m])
    //         ).values(),
    //       ];
    //       let testUrl = "";
    //       let last = false;
    //       const courseWithUrl = filteredCourses?.map((course, index) => {
    //         testUrl = "";
    //         course?.section?.map((item) => {
    //           item?.chapter?.map((item2) => {
    //             if (!last) {
    //               if (
    //                 item2.url &&
    //                 ((item2?.url?.includes("viosa.in") &&
    //                   item2?.url?.includes("profile")) ||
    //                   item2?.url?.includes("interviewpro.viosa.in") ||
    //                   item2?.url?.includes("resumatic.viosa.in") ||
    //                   item2?.url?.includes("resume.viosa.in") ||
    //                   item2?.url?.includes("pca.viosa.in"))
    //               ) {
    //                 testUrl = item2?.url;
    //                 last = true;
    //                 return item2;
    //               } else {
    //                 return;
    //               }
    //             }
    //             return item2;
    //           });
    //           return item;
    //         });
    //         console.log(testUrl, index);
    //         console.log("Test URL:", testUrl, "Index:", index);
    //         last = false;
    //         return { ...course, url: testUrl };
    //       });
    //       const filterCourse = courseWithUrl.filter(
    //         (course) =>
    //           course?.name !== "" &&
    //           course?.name !== "Resumatic AI" &&
    //           course?.name !== "Interview Pro"
    //       );
    //       setUserCourse(filterCourse);
    //       setLoading2(false);
    //     } catch (error) {
    //       setLoading2(false);
    //       console.error("Error fetching user courses:", error);
    //     }
    //   };
    //   const fetchUserDetails = async () => {
    //     try {
    //       const token = localStorage.getItem("token");
    //       console.log("token:", token);
    //       const userID = localStorage.getItem("userId");
    //       console.log("userId:", userID);
    //       // Fetch user details asynchronously
    //       const userDetailsResponse = await GetUserDetails(userID); // Example asynchronous function to fetch user details
    //       // const userDetailsData = await userDetailsResponse.json();

    //       // Log user details to console
    //       console.log('User Details:', userDetailsResponse.data.result[0]);

    //       // Update state with user details
    //       setUserDetails(userDetailsResponse.data.result[0]);
    //     } catch (error) {
    //       console.error('Error fetching user details:', error);
    //     }
    //   };
    //   setIframeData(null);
    //   fetchCourse();
    //   fetchUserDetails();
    // }, []);

    const fetchUserData = async () => {
      setLoading2(true);
      try {
        const token = localStorage.getItem("token");
        const userID = localStorage.getItem("userId");
        const userDetailsResponse = await GetUserDetails(userID);
        setUserDetails(userDetailsResponse.data.result[0]);
        // console.log("USER DEtails", userDetailsResponse.data.result[0]);
        console.log(
          "access code",
          "abaven_" + userDetailsResponse.data.result[0].serialNumber
        );
        const acccessCode =
          "abaven_" + userDetailsResponse.data.result[0].serialNumber;

        const response = await getAllUserCourses(token);
        const filteredCourses = [
          ...new Map(
            response.data.courses.map((course) => [course.courseID, course])
          ).values(),
        ];
        const userCourseWithUrl = filteredCourses.map((course) => {
          let courseUrl = "";
          course.section.forEach((section) => {
            section.chapter.forEach((chapter) => {
              if (
                !courseUrl &&
                chapter.url &&
                (chapter.url.includes("viosa.in") ||
                  chapter.url.includes("interviewpro.viosa.in") ||
                  chapter.url.includes("resumatic.viosa.in") ||
                  chapter.url.includes("resume.viosa.in") ||
                  chapter.url.includes("pca.viosa.in"))
              ) {
                courseUrl = chapter.url;
              }
            });
          });
          return { ...course, url: courseUrl };
        });
        const filteredUserCourses = userCourseWithUrl.filter(
          (course) =>
            course.name !== "" &&
            course.name !== "Resumatic AI" &&
            course.name !== "Interview Pro"
        );
        setUserCourse(filteredUserCourses);

        // Extract access code from user details
        let accessCode;
        if (userDetails?.serialNumber === 0) {
          accessCode = "abaven_" + stringToNumber(userDetails?._id);
        } else {
          accessCode = "abaven_" + userDetails?.serialNumber;
        }
        // console.log("access_code", accessCode);
        // Fetch assessment status for the user
        // const url = `https://pca.viosa.in/assessment-status-api.php?access_code=${acccessCode}`;
        // console.log(url);
        // const status = await fetch(url, {
        //   method: "GET",
        //   headers: {
        //     "Content-Type": "application/json",
        //     // Authorization: `Bearer ${token}`,
        //   },
        // });
        const status = await PcaStatus(acccessCode);
        // Make a request to the proxy endpoint
        // const status = await axios.get(
        //   `http://localhost:3200/api/proxy?access_code=${acccessCode}`
        // );

        console.log("Assessment status data:", status.data);
        console.log("Assessment status data:", status);
        // if (!status.ok) {
        //   throw new Error(`HTTP error! Status: ${status.status}`);
        // }

        const data2 = await status.data;
        console.log("Assessment status data2:", data2);
        const data = data2;
        console.log("Assessment status data:", data);
        // Assuming assessment status data is stored in `data`
        const isReportGenerated = data.assessment.some(
          (assessment) => assessment.status === 3
        );
        console.log("Assessment data:", isReportGenerated);
        setIsReportGenerated(isReportGenerated);
        // Process the assessment status data here...

        setLoading2(false);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setLoading2(false);
      }
    };

    fetchUserData();
  }, []);
  const { userData } = useSelector((state) => state.user);
  console.log(userData);
  const fetchMyCourse = async () => {
    try {
      setLoading2(true);

      const payload = {
        userId: localStorage.getItem("userId"),
      };
      const res = await getMyCourse(payload);
      console.log("res", res);
      const filteredCourses = [
        ...new Map(
          res.data.courses.map((course) => [course._id, course])
        ).values(),
      ];
      console.log("filter", filteredCourses);
      const userCourseWithUrl = filteredCourses.map((course) => {
        let courseUrl = "";
        course.section.forEach((section) => {
          section.chapter.forEach((chapter) => {
            if (
              !courseUrl &&
              chapter.url &&
              (chapter.url.includes("viosa.in") ||
                chapter.url.includes("interviewpro.viosa.in") ||
                chapter.url.includes("resumatic.viosa.in") ||
                chapter.url.includes("resume.viosa.in") ||
                chapter.url.includes("pca.viosa.in"))
            ) {
              courseUrl = chapter.url;
            }
          });
        });
        return { ...course, url: courseUrl };
      });
      const filteredUserCourses = userCourseWithUrl.filter(
        (course) =>
          course.name !== "" &&
          course.name !== "Resumatic AI" &&
          course.name !== "Corporate Sector Learning" &&
          course.name !== "Functional Skill Competency" &&
          course.name !== "Interview Pro"
      );
      setUserCourse(filteredUserCourses);
      setLoading2(false);
    } catch (error) {
      console.log(error);
      setLoading2(false);
    }
  };
  useEffect(() => {
    fetchMyCourse();
  }, []);
  const [select, setSelect] = useState(Number(searchParams.get("select")));
  const [data, setData] = useState({
    channel_id: 1645,
    cd: null,
    name: "",
    current_stage: 0,
    location: "",
    age: 1645,
    person_age: 21,
    access_code: "",
  });

  const stringToNumber = (str) => {
    let num = 0;
    for (let i = 0; i < str.length; i++) {
      num += str.charCodeAt(i);
    }
    return num;
  };

  const formSubmit = async (value) => {
    console.log("Form submitted with value:", value);
    setTimer(true);
    const token = localStorage.getItem("token");
    const userID = localStorage.getItem("userId");
    const response = await getAllUserCourses(token);
    const user = await GetUserDetails(userID);
    const filteredCourses = [
      ...new Map(response?.data?.courses?.map((m) => [m.courseID, m])).values(),
    ];
    const userCareerAssessmentCourse = careerAssessmentCD.filter((item) =>
      filteredCourses.some((i) => i.courseID === item.id)
    );
    // const dashboardCourse = filteredCourses.filter(
    //   (item) => item.courseID === "637239518381b37e31dbd273"
    // );
    // if (dashboardCourse.length > 0) {
    //   setDashboardCourse(true);
    //   setIframeData(
    //     "https://pca.viosa.in/student-dashboard/?channel_id=MTY0NQ="
    //   );
    //   return;
    // }

    setCareerAssessmentData(userCareerAssessmentCourse);
    if (userCareerAssessmentCourse[0]?.optionSelect && selectEnable === false) {
      setSelectEnable(true);
    } else if (!userCareerAssessmentCourse[0]?.optionSelect) {
      try {
        if (userCareerAssessmentCourse?.length === 0) {
          setIframeData("https://pca.viosa.in/");
          return;
        }
        setDashboardCourse(false);
        setLoading(true);
        const cdfind = user?.data?.result[0]?.cd?.filter((i) => {
          if (i?.split("_")[1] === searchParams.get("course_id")) {
            return i;
          }
        });
        const removeUndefined = cdfind?.filter((i) => i !== undefined);
        if (data.cd === null && removeUndefined[0]) {
          const allDATA = {
            ...data,
            cd: data.cd,
            userID: userID,
            courseID: localStorage.getItem("selectedCourse"),
            // name: user?.data?.result[0]?.name,
            name: userName,
            location: "India",
            current_stage:
              Number(data.current_stage) === 0 ? 1 : Number(data.current_stage),
            access_code:
              user.data.result[0]?.serialNumber === 0
                ? "abaven_" + stringToNumber(user?.data?.result[0]?._id)
                : "abaven_" + user.data.result[0]?.serialNumber,
          };
          localStorage.setItem("access_code", allDATA.access_code);
          localStorage.setItem("cd", data.cd);
          const d = await pca(
            { details: allDATA },
            token,
            searchParams.get("course_id")
          );
          console.log("AllData:", allDATA);
          console.log("D:", d);
          console.log(
            "d?.data?.result?.data?.redirect_link",
            d?.data?.result?.data?.redirect_link
          );
          setLoading(false);
          setIframeData(d?.data?.result?.data?.redirect_link);
          setTimer(false);
        }
        if (data.cd === null && !user?.data?.result[0]?.cd) return;
        setTimer(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
        setTimer(false);
      }
    }
  };

  useEffect(() => {
    if (data?.cd) {
      console.log("Form submitted with cd:", data.cd); // Add console log
      formSubmit();
    }
  }, [data.cd]);

  // useEffect(() => {
  //   setInterval(() => {
  //     const status = localStorage.getItem("status");
  //     if (status === 3) return;
  //     const access_code = localStorage.getItem("access_code");
  //     const cd = localStorage.getItem("cd2");
  //
  //     const fetch = async () => {
  //       const statusData = await statusCheck({
  //         access_code: access_code,
  //         cd: cd,
  //       });
  //       localStorage.setItem("status", statusData?.data?.status.status);
  //       if (statusData?.data?.status.status === 3) {
  //         localStorage.removeItem("access_code");
  //         localStorage.removeItem("cd");
  //         localStorage.removeItem("cd2");
  //       }
  //         if (statusData?.data?.status.status === 2) {
  //           localStorage.removeItem("access_code");
  //           localStorage.removeItem("cd");
  //           localStorage.removeItem("cd2");
  //           window.location.replace(
  //             "https://viosa.in/profile/career-assessment-test"
  //           );
  //         }
  //     };
  //     if(access_code && cd) {
  //       fetch();
  //     }
  //   }, 2000);
  //   return () => {
  //     clearInterval();
  //   };
  // }, []);

  const fetchData = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      console.log("token:", token);
      const userID = localStorage.getItem("userId");
      console.log("userId:", userID);
      const user = await GetUserDetails(userID);
      if (careerAssessmentData?.length === 0) {
        setIframeData("https://pca.viosa.in/");
        return;
      }
      const allDATA = {
        ...data,
        cd: data.cd,
        // name: user?.data?.result[0]?.name,
        name: userName,
        userID: userID,
        courseID: localStorage.getItem("selectedCourse"),
        current_stage:
          Number(data.current_stage) === 0 ? 1 : Number(data.current_stage),
        access_code:
          user.data.result[0]?.serialNumber === 0
            ? "abaven_" + stringToNumber(user?.data?.result[0]?._id)
            : "abaven_" + user.data.result[0]?.serialNumber,

        location: "India",
      };
      localStorage.setItem("cd2", allDATA.cd);
      console.log("userName", userName);
      const d = await pca(
        { details: allDATA },
        token,
        searchParams.get("course_id")
      );
      setIframeData(d?.data?.result?.data?.redirect_link);
      console.log("AllData:", allDATA);
      console.log("D:", d);
      console.log(
        "d?.data?.result?.data?.redirect_link",
        d?.data?.result?.data?.redirect_link
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const handleShowDashboard = (course) => {
    dispatch(addStatus(course, setIframeData, data));
  };
  // console.log(userCourse);
  // const hasCRM = userDetails?.crm;
  // console.log("hasCRM", hasCRM);

  const handleComprehensiveDownloadReport = async (cd) => {
    try {
      // Retrieve user ID and CD from local storage
      const userID = localStorage.getItem("userId");
      // const cd = localStorage.getItem("cd2"); // Assuming you stored cd in localStorage
      console.log("cd", cd);
      // Fetch user details to get the access code
      const user = await GetUserDetails(userID);
      if (
        !user ||
        !user.data ||
        !user.data.result ||
        user.data.result.length === 0
      ) {
        // Handle error: Unable to retrieve user details
        return;
      }

      // Extract access code from user details
      let accessCode;
      if (user.data.result[0]?.serialNumber === 0) {
        accessCode = "abaven_" + stringToNumber(user?.data?.result[0]?._id);
      } else {
        accessCode = "abaven_" + user.data.result[0]?.serialNumber;
      }

      // Make request to download the comprehensive report with analysis
      // const downloadUrl = `http://explore.abavenedu.com/access-login-api3.php?key=16fcb445ca660cdc695336bf83ead0cd&access_code=${accessCode}&cd=${cd}&clusters=1`;
      const downloadUrl = `https://pca.viosa.in/access-login-api3.php?&access_code=${accessCode}&cd=${cd}&clusters=1&directreport`;

      // Trigger download
      window.open(downloadUrl, "_blank");
    } catch (error) {
      console.error("Error downloading report:", error);
      // Handle error
    }
  };
  const cdReportMapping = {
    89: "Career Planning for 8th, 9th or 10th - English",
    129: "Career Planning for IB (MYP)/IGCSE/9th or 10th",
    226: "Career Planning for 8th, 9th or 10th - Hindi New",
    90: "Career Planning for 11th or 12th Commerce students - English",
    91: "Career Planning for 11th or 12th PCMB students - English",
    92: "Career Planning for 11th or 12th PCM (Maths) students - English",
    93: "Career Planning for 11th or 12th PCB (Bio) students - English",
    94: "Career Planning for 11th or 12th Arts students - English",
    130: "Career Planning for IBDP/ A Level (Business & Management Studies, Economics)",
    131: "Career Planning for IBDP/ A Level (Maths & Biology)",
    132: "Career Planning for IBDP/ A Level (Maths & Physics)",
    133: "Career Planning for IBDP/ A Level (Biology)",
    134: "Career Planning for IBDP/A Level (Humanities & Design)",
    153: "Career Planning for 11th or 12th Commerce students - Marathi",
    154: "Career Planning for 11th or 12th PCMB students - Marathi",
    155: "Career Planning for 11th or 12th PCM (Maths) students - Marathi",
    156: "Career Planning for 11th or 12th PCB (Bio) students - Marathi",
    157: "Career Planning for 11th or 12th Arts students - Marathi",
    165: "Career Planning for 11th or 12th Commerce students - Tamil",
    166: "Career Planning for 11th or 12th PCMB students - Tamil",
    167: "Career Planning for 11th or 12th PCM (Maths) students - Tamil",
    168: "Career Planning for 11th or 12th PCB (Bio) students - Tamil",
    169: "Career Planning for 11th or 12th Arts students - Tamil",
    176: "Career Planning for 11th or 12th Commerce students - Gujarati",
    177: "Career Planning for 11th or 12th PCMB students - Gujarati",
    178: "Career Planning for 11th or 12th PCM (Maths) students - Gujarati",
    179: "Career Planning for 11th or 12th PCB (Bio) students - Gujarati",
    180: "Career Planning for 11th or 12th Arts students - Gujarati",
    190: "Career Planning for 11th or 12th Commerce students - Malayalam",
    191: "Career Planning for 11th or 12th PCMB students - Malayalam",
    192: "Career Planning for 11th or 12th PCM (Maths) students - Malayalam",
    193: "Career Planning for 11th or 12th PCB (Bio) students - Malayalam",
    194: "Career Planning for 11th or 12th Arts students - Malayalam",
    227: "Career Planning for 11th or 12th Commerce students - Hindi New",
    228: "Career Planning for 11th or 12th PCMB students - Hindi New",
    229: "Career Planning for 11th or 12th PCM (Maths) students - Hindi New",
    230: "Career Planning for 11th or 12th PCB (Bio) students - Hindi New",
    231: "Career Planning for 11th or 12th Arts students - Hindi New",
    247: "Career Planning for 11th or 12th Commerce without Maths- English",
    83: "Engineering graduates/ Professionals",
    303: "Career Assessment for Pharmacy & Health Care Professionals",
    304: "Career Assessment for Agri Business Management Professionals",
    125: "Career Planning for Graduates ( Commerce in 12th) - English",
    126: "Career Planning for Graduates (Maths in 12th) - English",
    127: "Career Planning for Graduates (Bio in 12th) - English",
    128: "Career Planning for Graduates (Arts in 12th) - English",
    208: "Career Planning for Graduates (PCMB in 12th)",
    233: "Career Planning for Graduates ( Commerce in 12th) - Hindi New",
    234: "Career Planning for Graduates (Maths in 12th) - Hindi New",
    235: "Career Planning for Graduates (Bio in 12th) - Hindi New",
    236: "Career Planning for Graduates (Arts in 12th) - Hindi New",
    237: "Career Planning for Graduates (PCMB in 12th) - Hindi New",
  };

  return (
    <>
      {headerTop && <HeaderTop togglesidebar={togglesidebar} />}
      <div className="">
        {loading2 ? (
          <Spinner />
        ) : (
          <div className="">
            <h3
              style={{
                textAlign: "center",
                fontSize: "24px",
                padding: "20px",
                fontWeight: "bold",
              }}
            >
              Career Assessments
            </h3>
            <Show condition={userCourse.length === 0}>
              <NotEnrolled></NotEnrolled>
            </Show>
            <div className="col-12">
              {userDetails &&
                userDetails.crm &&
                userDetails.crm.length > 0 &&
                !select &&
                !iframeData && (
                  <>
                    <Show condition={userCourse?.length > 0}>
                      <div className="mt-4 recommended">
                        <strong className="mb-8 ml-4 recommended continue-course">
                          My Reports
                        </strong>

                        {isReportGenerated &&
                        userDetails &&
                        userDetails.crm &&
                        userDetails.crm.length > 0 ? (
                          <>
                            {userDetails.crm.map((item, index) => (
                              <div
                                key={index}
                                className="title-container"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  marginTop: "15px",
                                  width: "100%",
                                  backgroundColor: "rgb(255, 247, 231)",
                                  borderRadius: "4px",
                                  boxShadow:
                                    "0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1)",
                                  color: "rgb(0, 0, 0)",
                                  fontSize: " 14px ",
                                  fontWeight: "500",
                                  height: "auto",
                                  padding: "20px",
                                }}
                              >
                                {/* <h2>{cdReportMapping[item.cd]}</h2>
                                  <button
                                    className="download-report-btn"
                                    onClick={() =>
                                      handleComprehensiveDownloadReport(item.cd)
                                    }
                                  >
                                    Download Comprehensive Direct Report
                                  </button> */}
                                <h2>{cdReportMapping[item.cd]}</h2>
                                <button
                                  className="download-report-btn"
                                  onClick={() =>
                                    handleComprehensiveDownloadReport(item.cd)
                                  }
                                >
                                  Download Comprehensive Direct Report
                                  {/* Render the name of the report */}
                                  {/* {cdReportMapping[item.cd]} */}
                                </button>
                              </div>
                            ))}
                          </>
                        ) : (
                          <div
                            style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            No report to generate
                          </div>
                        )}
                      </div>
                    </Show>
                  </>
                )}
              {userCourse?.length > 0 && !select && !iframeData && (
                <>
                  <Show condition={userCourse?.length > 0}>
                    <div className="mt-4 recommended">
                      <strong className="mb-8 ml-4 recommended continue-course">
                        My Courses
                      </strong>

                      <div className="row mt-12 mx-4 justify-content-start">
                        {userCourse?.map((course, index) => (
                          <div
                            key={index}
                            className="mb-8 cursor-pointer card-border mt-24"
                            onClick={() => {
                              console.log("Course clicked:", course); // Added console log
                              // setButtonHeading("Play Course");
                              // setIsOpened(true);
                              // setCourseID(course.courseID);
                              // dispatch(courseId(course.courseID));
                            }}
                          >
                            <div>
                              <img
                                className="block mx-auto card-image"
                                src={course.thumbnail || uiUx}
                                onError={(e) => {
                                  e.target.src =
                                    "https://viosa-admin.vercel.app/static/media/Viosalogo.4b791508eaabbb0f66e514237ec67cb6.svg";
                                }}
                                alt=""
                              />
                              <div>
                                <div className="heading-card-div">
                                  <OverlayTrigger
                                    key={
                                      course.name ? course.name : "Course Name"
                                    }
                                    placement="bottom"
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        {course.name
                                          ? course.name
                                          : "Course Name"}
                                      </Tooltip>
                                    }
                                  >
                                    <h1 className="training pt-3">
                                      {course.name
                                        ? course.name
                                        : "Course Name"}
                                    </h1>
                                  </OverlayTrigger>
                                </div>
                                {!course?.status ||
                                course?.status?.status === 0 ||
                                course?.status?.status === 1 ? (
                                  <div
                                    style={{
                                      color: "#fea302",
                                      marginBottom: "10px",
                                    }}
                                    onClick={() => {
                                      console.log(
                                        "Course status:",
                                        course.status
                                      ); // Added console log

                                      if (!course.url) {
                                        alert(
                                          "Temporarily Unavailable! Please Try Again after Sometime."
                                        );
                                      } else {
                                        localStorage.setItem(
                                          "selectedCourse",
                                          course?.courseID
                                        );
                                        localStorage.setItem(
                                          "access_code",
                                          course?.access_code
                                        );
                                        window.location.replace(course?.url);
                                      }
                                    }}
                                    className="px-2 text-end"
                                  >
                                    {/* {progressCircle?.includes(course._id)} */}
                                    <button className="join-session-btn w-100">
                                      Start Now
                                    </button>
                                  </div>
                                ) : (
                                  <div className="mb-3">
                                    <div
                                      style={{
                                        color: "#fea302",
                                        marginBottom: "10px",
                                        width: "100%",
                                      }}
                                      onClick={() => {
                                        handleShowDashboard(course);
                                      }}
                                      className="text-end"
                                    >
                                      {/* {progressCircle?.includes(course._id)} */}
                                      <button
                                        style={{ width: "100%" }}
                                        className="join-session-btn"
                                      >
                                        {course?.status?.status === 2
                                          ? "Download Report"
                                          : "Dashboard"}
                                      </button>
                                    </div>
                                    {course?.status?.status === 3 && (
                                      <a
                                        style={{
                                          color: "#fea302",
                                          marginBottom: "10px",
                                          width: "100%",
                                        }}
                                        href={`https://pca.viosa.in/access-login-api3.php?access_code=${course?.access_code}&cd=${course?.crm[0]?.cd}&clusters=0&directreport`}
                                        className="text-end"
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {/* {progressCircle?.includes(course._id)} */}
                                        <button className="join-session-btn w-100">
                                          Report
                                        </button>
                                      </a>
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Show>
                </>
              )}
              {select !== 0 &&
                !iframeData &&
                (!store?.status ||
                  store?.status?.status === 0 ||
                  store?.status?.status === 1) && (
                  <>
                    <button
                      className="btn-btn-main-2"
                      style={{ width: "100px" }}
                      onClick={() =>
                        window.location.replace(
                          "/profile/career-assessment-test"
                        )
                      }
                    >
                      Back
                    </button>
                    <div className="d-flex justify-content-center">
                      <div className="main-box">
                        <div className="box-2">
                          <form>
                            <div className="form-group mt-2">
                              <label>
                                Enter You Name (It will be shown on report)
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="userName"
                                value={userName}
                                onChange={(e) => setUserName(e.target.value)}
                                required
                              />
                            </div>
                            {select === 1 && (
                              <div className="form-group mt-2">
                                <label htmlFor="select">Please Select</label>
                                <select
                                  name="drop_cdi"
                                  className="form-control"
                                  style={{ border: "2px solid #1F409B" }}
                                  required
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      cd: Number(e.target.value),
                                    })
                                  }
                                  id="ch_id"
                                >
                                  <option selected value>
                                    Please select your Current Doing
                                  </option>
                                  <option value={89}>
                                    Career Planning for 8th, 9th or 10th -
                                    English
                                  </option>
                                  <option value={129}>
                                    Career Planning for IB (MYP)/IGCSE/9th or
                                    10th
                                  </option>
                                  <option value={226}>
                                    Career Planning for 8th, 9th or 10th - Hindi
                                    New
                                  </option>
                                </select>
                              </div>
                            )}
                            {select === 2 && (
                              <div className="form-group mt-2">
                                <label htmlFor="select">Please Select</label>
                                <select
                                  name="drop_cdi"
                                  className="form-control"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      cd: Number(e.target.value),
                                    })
                                  }
                                  required
                                  id="ch_id"
                                  // onChange=""
                                >
                                  <option selected value>
                                    Please select your Current Doing
                                  </option>
                                  <option value={90}>
                                    Career Planning for 11th or 12th Commerce
                                    students - English
                                  </option>
                                  <option value={91}>
                                    Career Planning for 11th or 12th PCMB
                                    students - English
                                  </option>
                                  <option value={92}>
                                    Career Planning for 11th or 12th PCM (Maths)
                                    students - English
                                  </option>
                                  <option value={93}>
                                    Career Planning for 11th or 12th PCB (Bio)
                                    students - English
                                  </option>
                                  <option value={94}>
                                    Career Planning for 11th or 12th Arts
                                    students - English
                                  </option>
                                  <option value={130}>
                                    Career Planning for IBDP/ A Level (Business
                                    &amp; Management Studies, Economics)
                                  </option>
                                  <option value={131}>
                                    Career Planning for IBDP/ A Level (Maths
                                    &amp; Biology)
                                  </option>
                                  <option value={132}>
                                    Career Planning for IBDP/ A Level (Maths
                                    &amp; Physics)
                                  </option>
                                  <option value={133}>
                                    Career Planning for IBDP/ A Level (Biology)
                                  </option>
                                  <option value={134}>
                                    Career Planning for IBDP/A Level (Humanities
                                    &amp; Design)
                                  </option>
                                  <option value={153}>
                                    Career Planning for 11th or 12th Commerce
                                    students - Marathi
                                  </option>
                                  <option value={154}>
                                    Career Planning for 11th or 12th PCMB
                                    students - Marathi
                                  </option>
                                  <option value={155}>
                                    Career Planning for 11th or 12th PCM (Maths)
                                    students - Marathi
                                  </option>
                                  <option value={156}>
                                    Career Planning for 11th or 12th PCB ( Bio)
                                    students - Marathi
                                  </option>
                                  <option value={157}>
                                    Career Planning for 11th or 12th Arts
                                    students - Marathi
                                  </option>
                                  <option value={165}>
                                    Career Planning for 11th or 12th Commerce
                                    students - Tamil
                                  </option>
                                  <option value={166}>
                                    Career Planning for 11th or 12th PCMB
                                    students - Tamil
                                  </option>
                                  <option value={167}>
                                    Career Planning for 11th or 12th PCM (Maths)
                                    students - Tamil
                                  </option>
                                  <option value={168}>
                                    Career Planning for 11th or 12th PCB ( Bio)
                                    students - Tamil
                                  </option>
                                  <option value={169}>
                                    Career Planning for 11th or 12th Arts
                                    students - Tamil
                                  </option>
                                  <option value={176}>
                                    Career Planning for 11th or 12th Commerce
                                    students - Gujarati
                                  </option>
                                  <option value={177}>
                                    Career Planning for 11th or 12th PCMB
                                    students - Gujarati
                                  </option>
                                  <option value={178}>
                                    Career Planning for 11th or 12th PCM (Maths)
                                    students - Gujarati
                                  </option>
                                  <option value={179}>
                                    Career Planning for 11th or 12th PCB ( Bio)
                                    students - Gujarati
                                  </option>
                                  <option value={180}>
                                    Career Planning for 11th or 12th Arts
                                    students - Gujarati
                                  </option>
                                  <option value={190}>
                                    Career Planning for 11th or 12th Commerce
                                    students - Malayalam
                                  </option>
                                  <option value={191}>
                                    Career Planning for 11th or 12th PCMB
                                    students - Malayalam
                                  </option>
                                  <option value={192}>
                                    Career Planning for 11th or 12th PCM (Maths)
                                    students - Malayalam
                                  </option>
                                  <option value={193}>
                                    Career Planning for 11th or 12th PCB ( Bio)
                                    students - Malayalam
                                  </option>
                                  <option value={194}>
                                    Career Planning for 11th or 12th Arts
                                    students - Malayalam
                                  </option>
                                  <option value={227}>
                                    Career Planning for 11th or 12th Commerce
                                    students - Hindi New
                                  </option>
                                  <option value={228}>
                                    Career Planning for 11th or 12th PCMB
                                    students - Hindi New
                                  </option>
                                  <option value={229}>
                                    Career Planning for 11th or 12th PCM (Maths)
                                    students - Hindi New
                                  </option>
                                  <option value={230}>
                                    Career Planning for 11th or 12th PCB ( Bio)
                                    students - Hindi New
                                  </option>
                                  <option value={231}>
                                    Career Planning for 11th or 12th Arts
                                    students - Hindi New
                                  </option>
                                  <option value={247}>
                                    Career Planning for 11th or 12th Commerce
                                    without Maths- English
                                  </option>
                                </select>
                              </div>
                            )}
                            {select === 3 && (
                              <div className="form-group mt">
                                Please Select
                                <select
                                  name="drop_cdi"
                                  className="form-control"
                                  style={{ border: "2px solid #1F409B" }}
                                  required
                                  id="ch_id"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      cd: Number(e.target.value),
                                    })
                                  }
                                >
                                  <option selected value>
                                    Please select your Current Doing
                                  </option>
                                  <option value={129}>
                                    Career Planning for IB (MYP)/IGCSE/9th or
                                    10th
                                  </option>
                                </select>
                              </div>
                            )}
                            {select === 4 && (
                              <div className="form-group mt-2">
                                <label>Please Select</label>
                                <select
                                  name="drop_cdi"
                                  className="form-control"
                                  style={{ border: "2px solid #1F409B" }}
                                  required
                                  id="ch_id"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      cd: Number(e.target.value),
                                    })
                                  }
                                >
                                  <option selected value>
                                    Please select your Current Doing
                                  </option>
                                  <option value={131}>
                                    Career Planning for IBDP/ A Level (Maths
                                    &amp; Biology)
                                  </option>
                                  <option value={130}>
                                    Career Planning for IBDP/ A Level (Business
                                    &amp; Management Studies, Economics)
                                  </option>
                                  <option value={134}>
                                    Career Planning for IBDP/A Level (Humanities
                                    &amp; Design)
                                  </option>
                                  <option value={132}>
                                    Career Planning for IBDP/ A Level (Maths
                                    &amp; Physics)
                                  </option>
                                  <option value={133}>
                                    Career Planning for IBDP/ A Level (Biology)
                                  </option>
                                </select>
                              </div>
                            )}
                            {select === 5 && (
                              <div className="form-group mt-2">
                                <label>Please Select</label>
                                <select
                                  name="drop_cdi"
                                  className="form-control"
                                  style={{ border: "2px solid #1F409B" }}
                                  required
                                  id="ch_id"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      cd: Number(e.target.value),
                                    })
                                  }
                                >
                                  <option selected value>
                                    Please select your Current Doing
                                  </option>
                                  <option value={131}>
                                    Career Planning for IBDP/ A Level (Maths
                                    &amp; Biology)
                                  </option>
                                  <option value={130}>
                                    Career Planning for IBDP/ A Level (Business
                                    &amp; Management Studies, Economics)
                                  </option>
                                  <option value={134}>
                                    Career Planning for IBDP/A Level (Humanities
                                    &amp; Design)
                                  </option>
                                  <option value={132}>
                                    Career Planning for IBDP/ A Level (Maths
                                    &amp; Physics)
                                  </option>
                                  <option value={133}>
                                    Career Planning for IBDP/ A Level (Biology)
                                  </option>
                                </select>
                              </div>
                            )}
                            {select === 6 && (
                              <div className="form-group mt-2">
                                <label>Please Select</label>
                                <select
                                  name="drop_cdi"
                                  className="form-control"
                                  style={{ border: "2px solid #1F409B" }}
                                  required
                                  id="ch_id"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      cd: Number(e.target.value),
                                    })
                                  }
                                >
                                  <option selected value>
                                    Please select your Current Doing
                                  </option>
                                  <option value={96}>
                                    Career Planning for Professionals &amp;
                                    Senior Professionals - English
                                  </option>
                                  <option value={158}>
                                    Career Planning for Professionals &amp;
                                    Senior Professionals - Marathi
                                  </option>
                                  <option value={170}>
                                    Career Planning for Professionals &amp;
                                    Senior Professionals - Tamil
                                  </option>
                                  <option value={181}>
                                    Career Planning for Professionals &amp;
                                    Senior Professionals - Gujarati
                                  </option>
                                  <option value={195}>
                                    Career Planning for Professionals &amp;
                                    Senior Professionals - Malayalam
                                  </option>
                                  <option value={232}>
                                    Career Planning for Professionals &amp;
                                    Senior Professionals - Hindi New
                                  </option>
                                </select>
                              </div>
                            )}
                            {select === 7 && (
                              <div className="form-group mt-2">
                                <label>Please Select</label>
                                <select
                                  name="drop_cdi"
                                  className="form-control"
                                  style={{ border: "2px solid #1F409B" }}
                                  required
                                  id="ch_id"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      cd: Number(e.target.value),
                                    })
                                  }
                                >
                                  <option selected value>
                                    Please select your Current Doing
                                  </option>
                                  <option value={83}>
                                    Engineering graduates/ Professionals
                                  </option>
                                </select>
                              </div>
                            )}
                            {select === 8 && (
                              <div className="form-group">
                                <label>Please Select</label>
                                <select
                                  name="drop_cdi"
                                  className="form-control"
                                  style={{ border: "2px solid #1F409B" }}
                                  required
                                  id="ch_id"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      cd: Number(e.target.value),
                                    })
                                  }
                                >
                                  <option selected value>
                                    Please select your Current Doing
                                  </option>
                                  <option value={303}>
                                    Career Assessment for Pharmacy &amp; Health
                                    Care Professionals
                                  </option>
                                </select>
                              </div>
                            )}
                            {select === 9 && (
                              <div className="form-group">
                                <label>Please Select </label>
                                <select
                                  name="drop_cdi"
                                  className="form-control"
                                  style={{ border: "2px solid #1F409B" }}
                                  required
                                  id="ch_id"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      cd: Number(e.target.value),
                                    })
                                  }
                                >
                                  <option selected value>
                                    Please select your Current Doing
                                  </option>
                                  <option value={304}>
                                    Career Assessment for Agri Business
                                    Management Professionals
                                  </option>
                                </select>
                              </div>
                            )}
                            {select === 10 && (
                              <div className="form-group mt-2">
                                <label>Please Select</label>
                                <select
                                  name="drop_cdi"
                                  className="form-control"
                                  style={{ border: "2px solid #1F409B" }}
                                  required
                                  id="ch_id"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      cd: Number(e.target.value),
                                    })
                                  }
                                >
                                  <option selected value>
                                    Please select your Current Doing
                                  </option>
                                  <option value={125}>
                                    Career Planning for Graduates ( Commerce in
                                    12th) - English
                                  </option>
                                  <option value={126}>
                                    Career Planning for Graduates (Maths in
                                    12th) - English
                                  </option>
                                  <option value={127}>
                                    Career Planning for Graduates (Bio in 12th)
                                    - English
                                  </option>
                                  <option value={128}>
                                    Career Planning for Graduates (Arts in 12th)
                                    - English
                                  </option>
                                  <option value={208}>
                                    Career Planning for Graduates (PCMB in 12th)
                                  </option>
                                  <option value={233}>
                                    Career Planning for Graduates ( Commerce in
                                    12th) - Hindi New
                                  </option>
                                  <option value={234}>
                                    Career Planning for Graduates (Maths in
                                    12th) - Hindi New
                                  </option>
                                  <option value={235}>
                                    Career Planning for Graduates (Bio in 12th)
                                    - Hindi New
                                  </option>
                                  <option value={236}>
                                    Career Planning for Graduates (Arts in 12th)
                                    - Hindi New
                                  </option>
                                  <option value={237}>
                                    Career Planning for Graduates (PCMB in 12th)
                                    - Hindi New
                                  </option>
                                </select>
                              </div>
                            )}
                            <div className="form-group mt-2">
                              <label>Current Stage</label>
                              <select
                                className="form-control"
                                onChange={(e) =>
                                  setData({
                                    ...data,
                                    current_stage: Number(e.target.value),
                                  })
                                }
                              >
                                <option value={1}>
                                  I have no idea about my career
                                </option>
                                <option value={2}>
                                  I am confused among various career options{" "}
                                </option>
                                <option value={3}>
                                  I am bit sure but want to explore other
                                  options as well
                                </option>
                                <option value={4}>
                                  I am very sure about my career choice but need
                                  an execution plan
                                </option>
                              </select>
                            </div>
                            <div className="form-group mt-2">
                              <button
                                disabled={timer ? true : false}
                                className={
                                  timer ? "btn-btn-main-gray" : "btn-btn-main"
                                }
                                onClick={(e) => fetchData(e)}
                              >
                                {timer ? "Loading" : "Submit"}
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
            {iframeData && (
              <iframe
                src={iframeData}
                width="100%"
                title="Career Assessment"
                height="900px"
              ></iframe>
            )}
          </div>
        )}
      </div>
    </>
  );
};
