import React, { useEffect, useState } from "react";
import star from "../icons/star.svg";
import stardark from "../icons/stardark.svg";
import cartCourse from "../icons/placeholder2.png";
import Cartmodal from "./Cartmodal/Cartmodal";
import { useDispatch, useSelector } from "react-redux";
import { ApplyCoupon, clearCart, DeleteCartItem } from "Axios/apis";
import { AddtoCart } from "redux/user/userAction";
import { Spinner } from "react-bootstrap";
import "./cart.css";
import prettyName from "pretty-name";
import axios from "axios";
import CartIcon from "../icons/carticon.png";
import { motion } from "framer-motion";
import HeaderTop from "./HeaderTop";
import { toast, ToastContainer } from "react-toastify";

export const Cart = (props) => {
  const [deleteloading, setdeleteloading] = useState(false);
  const dispatch = useDispatch();
  const userID = localStorage.getItem("userId");
  const [apply, setApply] = useState(false);
  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState("");
  const [newValue, setNewValue] = useState();
  const [value, setValue] = useState({
    value: 0,
    type: "",
  });
  // const [cartitem,setcartitem]=useState([])
  const handleAllTheBest = async () => {
    setLoading(true);
    if (coupon === "") {
      alert("Please Enter Coupon Code");
      setLoading(false);
      return;
    }
    if (totalcartprice === 0) {
      alert("Please Add Course to Cart");
      setLoading(false);
      return;
    }

    try {
      const data = await ApplyCoupon({
        couponCode: coupon,
      });
      const { value, type } = data?.data;
      setLoading(false);
      setValue({
        value: value,
        type: type,
      });
      console.log(value, type);
      if (type === "percentage") {
        if (value === 100 || value === "100") {
          setNewValue(0);
        } else {
          setNewValue((totalcartprice * value) / 100);
        }
      }
      if (type === "price") {
        setNewValue(totalcartprice - value);
      }
      setApply(true);
    } catch (err) {
      console.log(err);
      setLoading(false);
      alert("Invalid Coupon Code or Coupon Code Expired");
    }
  };

  const { cart } = useSelector((state) => state.user);
  const courseIds = cart?.map((item) => item.courseID);
  // //console.log(Array.isArray(courseIds));
  // //console.log(cart);
  const totalcartprice = cart?.reduce((acc, item) => {
    return acc + item?.price;
  }, 0);
  useEffect(() => {
    if (totalcartprice && newValue) {
      if (value.type === "price") setNewValue(totalcartprice - value.value);
      else {
        if (value.value === 100 || value.value === "100") {
          setNewValue(0);
        } else {
          setNewValue((totalcartprice * value.value) / 100);
        }
      }
    }
  }, [totalcartprice]);
  const RemoveFromCart = async (data) => {
    const cart = {
      userId: userID,
      cartId: data?._id,
    };
    try {
      const res = await DeleteCartItem(cart);
      dispatch(AddtoCart(res?.data?.result?.cart));
      // //console.log(res);
    } catch (error) {
      // //console.log(error);
      toast.warning("Failed to remove from Cart, please try again");
    }
    setdeleteloading(false);
  };

  const totalprice = cart?.reduce((acc, item) => {
    return acc + item?.price;
  }, 0);

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };
  const buyNow = async () => {
    if (newValue === 0 || newValue === "0") {
      const data = {
        courseId: courseIds,
        name: localStorage.getItem("userName"),
        email: localStorage.getItem("email"),
        amount: 0,
        currency: "INR",
        courseName: cart?.map((item) => item?.name).join(","),
        userId: localStorage.getItem("userId"),
        status: "SUCCESS",
      };
      try {
        axios
          .post("https://api.viosa.in/user/payment/buy_course_free", data)
          // .post(
          //   "https://api-live.viosa.in/user/payment/buy_course_free",
          //   data
          // )
          // .post("http://localhost:3200/user/payment/buy_course_free", data)
          .then((res) => {
            const id = {
              userId: localStorage.getItem("userId"),
            };
            const response = clearCart(id);
            dispatch(AddtoCart(response?.data?.result?.cart));
            setNewValue(null);
            setopencartmodal("success");
          });
      } catch (err) {
        setopencartmodal("fail");
      }
    } else {
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        toast.warning("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const paymentData = {
        courseIds: courseIds,
        userId: localStorage.getItem("userId"),
        amount:
          newValue + newValue * 0.18 || Number(totalprice) + totalprice * 0.18,
        currency: "INR",
        status: "INITIATED",
        courseName: cart?.map((item) => item?.name).join(","),
      };
      axios
        .post(
          "https://api.viosa.in/user/payment/buy_course_request",
          // "http://localhost:3200/user/payment/buy_course_request",
          // "https://api-live.viosa.in/user/payment/buy_course_request",
          paymentData
        )
        .then((response) => {
          const { amount, id: order_id, currency } = response.data.result;
          const options = {
            key: "rzp_live_nsCl8adCCyvN7S", // Enter the Key ID generated from the Dashboard
            // key: "rzp_test_NvXh4eCQDyCstP", // Enter the Key ID generated from the Dashboard
            amount,
            secret: "69lDy6zhJV9KdRhb9RfnXsft",
            currency: currency,
            name: "Viosa Learning.",
            description: "Course Payment - Viosa Learning.",
            image:
              "https://viosa-admin.vercel.app/static/media/Viosalogo.4b791508eaabbb0f66e514237ec67cb6.svg",
            order_id: order_id,
            handler: async function (response) {
              const data = {
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                orderId: order_id,
                courseId: courseIds,
                name: localStorage.getItem("userName"),
                email: localStorage.getItem("email"),
                amount:
                  newValue + newValue * 0.18 ||
                  Number(totalprice) + totalprice * 0.18,
                currency: "INR",
                courseName: cart?.map((item) => item?.name).join(","),
                userId: localStorage.getItem("userId"),
                status: "SUCCESS",
              };
              try {
                axios
                  .post(
                    "https://api.viosa.in/user/payment/buy_course_verify",
                    // "http://localhost:3200/user/payment/buy_course_verify",
                    // "https://api-live.viosa.in/user/payment/buy_course_verify",
                    data
                  )
                  .then((res) => {
                    const id = {
                      userId: localStorage.getItem("userId"),
                    };
                    const response = clearCart(id);
                    dispatch(AddtoCart(response?.data?.result?.cart));
                    setNewValue(null);
                    setopencartmodal("success");
                  });
              } catch (err) {
                setopencartmodal("fail");
              }
            },

            prefill: {
              name: localStorage.getItem("userName"),
              email: localStorage.getItem("email"),
              contact: "9999999999",
            },
            theme: {
              color: "#F37254",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
          paymentObject.on("payment.failed", function (response) {
            const newdata = {
              razorpay_payment_id: response.error.metadata.payment_id,
              razorpay_order_id: response.error.metadata.order_id,
              orderId: response.error.metadata.order_id,
              courseId: courseIds,
              name: localStorage.getItem("userName"),
              email: localStorage.getItem("email"),
              amount:
                newValue + newValue * 0.18 ||
                Number(totalprice) + totalprice * 0.18,
              currency: "INR",
              courseName: cart?.map((item) => item?.name).join(","),
              userId: localStorage.getItem("userId"),
              status: "FAILED",
              reason: response.error.description,
            };
            axios.post(
              // "https://api.viosa.in/user/payment/buy_course_verify",
              "https://api.viosa.in/user/payment/buy_course_verify-failed",
              // "https://api-live.viosa.in/user/payment/buy_course_verify-failed",
              // "http://localhost:3200/user/payment/buy_course_verify-failed",
              newdata
            );
            setopencartmodal("fail");
          });
        });
    }
  };

  // const SaveForLater = () => {};
  // const AddToCart = () => {};
  const [opencartmodal, setopencartmodal] = useState("");
  const [id, setID] = useState("");

  console.log(newValue);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <ToastContainer />
        <div className="row catalogue">
          <HeaderTop name="Cart" togglesidebar={props.togglesidebar} />
        </div>
        <div className="mt-3">
          <h1 className=" cart-heading">Courses in Cart</h1>
          <div className="hero-cart flex-col lg:flex-row-reverse">
            <div>
              <div className="mb-6 cart-price" style={{ height: "auto" }}>
                <p className="price-checkout">Price Details</p>
                <div className="mt-2 mb-4">
                  {cart?.map((course, idx) => {
                    return (
                      <div key={idx} className="flex justify-between">
                        <div className="row">
                          <div className="col-8">
                            <p style={{ width: "170px" }}>
                              {prettyName(course.name)}
                            </p>
                          </div>
                          <div className="col-4">
                            <p>₹ {course.price}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                <div className="flex justify-content-end">
                  <div style={{ paddingLeft: 30 }} className="col-12 mb-2">
                    <h3 className="fw-bold fs-20">
                      Total &nbsp;&nbsp;{" "}
                      <a
                        style={{
                          textDecoration: apply ? "line-through" : "none",
                          marginRight: "10px",
                        }}
                      >
                        ₹ {totalcartprice}
                      </a>
                      {newValue > 0 && <span>₹ {Math.round(newValue)}</span>}
                      {newValue === 0 && <span>₹ 0</span>}
                      {newValue !== 0 && (
                        <small
                          className="text-danger fs-10"
                          style={{ fontSize: 10 }}
                        >
                          + GST (18%)
                        </small>
                      )}
                    </h3>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    className="checkout"
                    onClick={() => cart?.length && buyNow()}
                  >
                    Proceed to Payment
                  </button>
                </div>
              </div>

              <div className="cart-code p-[15px]">
                <p className="price-checkout">Coupon Code</p>
                <div className="my-4 promotion-input">
                  <input
                    className="apply-input"
                    type="text"
                    onChange={(e) => setCoupon(e.target.value)}
                  />
                  <button
                    for="my-modal-10"
                    onClick={handleAllTheBest}
                    className="apply-button"
                  >
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Apply"
                    )}
                  </button>
                </div>
                <small
                  style={{
                    border: newValue > 0 ? "2px dashed #fea302" : "",
                    padding: "5px",
                  }}
                >
                  {newValue > 0 && "Coupon Applied"}
                </small>
              </div>
            </div>

            <div>
              {cart?.length > 0 ? (
                <div>
                  {cart?.map((data, idx) => {
                    return (
                      <div className="flex p-4 my-2 mx-4 course" key={idx}>
                        <div className="flex justify-center">
                          <img
                            className="cart-image"
                            src={data?.thumbnail || cartCourse}
                            alt=""
                          />
                          <div className="ml-4">
                            <div>
                              <p style={{ width: "100px" }}>{data?.name}</p>
                            </div>
                            <div className="flex items-center my-2 gap-x-2">
                              <p
                                className="text-xl"
                                style={{ color: "#FFA200" }}
                              >
                                4.0
                              </p>
                              <img className="d-none-500" src={star} alt="" />
                              <img className="d-none-500" src={star} alt="" />
                              <img className="d-none-500" src={star} alt="" />
                              <img className="d-none-500" src={star} alt="" />
                              <img
                                className="d-none-500"
                                src={stardark}
                                alt=""
                              />
                            </div>
                            <p style={{ color: "red" }}>₹ {data?.price}</p>
                          </div>
                        </div>

                        <div className="course-res ml-[300px] ">
                          <div>
                            {deleteloading && id === data?._id ? (
                              <Spinner animation="border" size="sm" />
                            ) : (
                              <button
                                onClick={() => {
                                  setdeleteloading(true);
                                  setID(data?._id);
                                  RemoveFromCart(data);
                                }}
                                className="mb-[35px]"
                              >
                                Remove
                              </button>
                            )}

                            {/* <button
                          style={{ width: "100px" }}
                          onClick={SaveForLater}
                        >
                          Save for later
                        </button> */}
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/* <p className="mt-12 mb-8 cart-heading">Saved for later</p> */}
                  {/* <div className="flex p-4 course">
                <div className="flex justify-center">
                  <img className="cart-image" src={cartCourse} alt="" />
                  <div className="ml-4">
                    <p>UI & UX Designing</p>
                    <div className="flex items-center my-2 mx-4 gap-x-2">
                      <p className="text-xl" style={{ color: "#FFA200" }}>
                        4.0
                      </p>
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                    </div>
                    <img src={nine} alt="" />
                  </div>
                </div>

                <div className="ml-[340px]">
                  <div>
                    <button className="mb-[35px]">Remove</button>
                    <button>Add to Cart</button>
                  </div>
                </div>
              </div> */}
                </div>
              ) : (
                <div>
                  <div className="flex p-4 my-2 mx-4 course">
                    <div className="flex justify-center">
                      <img
                        className="cart-image"
                        style={{ width: "80px", height: "60px" }}
                        src={CartIcon}
                        alt=""
                      />
                    </div>

                    <div className="course-res ml-[300px] ">
                      <div>
                        <p className="mt-3">Your Cart is Empty</p>

                        {/* <button
                          style={{ width: "100px" }}
                          onClick={SaveForLater}
                        >
                          Save for later
                        </button> */}
                      </div>
                    </div>
                  </div>

                  {/* <p className="mt-12 mb-8 cart-heading">Saved for later</p> */}
                  {/* <div className="flex p-4 course">
                <div className="flex justify-center">
                  <img className="cart-image" src={cartCourse} alt="" />
                  <div className="ml-4">
                    <p>UI & UX Designing</p>
                    <div className="flex items-center my-2 mx-4 gap-x-2">
                      <p className="text-xl" style={{ color: "#FFA200" }}>
                        4.0
                      </p>
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                      <img src={star} alt="" />
                    </div>
                    <img src={nine} alt="" />
                  </div>
                </div>

                <div className="ml-[340px]">
                  <div>
                    <button className="mb-[35px]">Remove</button>
                    <button>Add to Cart</button>
                  </div>
                </div>
              </div> */}
                </div>
              )}
            </div>
          </div>
        </div>

        {/*
                Button will appear here
                <div>
                    <input type="checkbox" id="my-modal-10" className="modal-toggle" />
                    <div className="modal">
                        <div className="modal-box relative">
                            <label for="my-modal-10" className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
                            <h3 className="text-lg font-bold">Congratulations random Internet user!</h3>
                            <p className="py-4">You've been selected for a chance to get one year of subscription to use Wikipedia for free!</p>
                        </div>
                    </div>
                </div> */}
        {opencartmodal == "fail" && (
          <Cartmodal
            setStatus={setopencartmodal}
            paymentsuccess={false}
            status="failure"
          />
        )}
        {opencartmodal == "success" && (
          <Cartmodal
            setStatus={setopencartmodal}
            paymentsuccess={true}
            status="success"
          />
        )}
      </div>
    </motion.div>
  );
};
