import React, { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import HeaderTop from "./HeaderTop";
import Spinner from "./Spinner/Spinner";
import "./CollegeDashboard.css";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import {
  createTeam,
  getCollegeDashboardApi,
  getCollegeInterviewApi,
  getCollegeResumeApi,
  GetTeam,
  GetTrailResumeById,
} from "Axios/apis";
import { useDispatch, useSelector } from "react-redux";
import { FaLessThanEqual, FaRegEdit } from "react-icons/fa";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { MdDelete } from "react-icons/md";

import axios from "axios";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { pdfjs } from "react-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
const CollegeDashboard = (props, data) => {
  const role = localStorage.getItem("role");
  const [courses, setCourses] = useState([]);
  const [loadspinner, setloading] = useState(false);
  const [dashboardData, setDashBoardData] = useState(null);
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [table1Page, setTable1Page] = useState(1);
  const [table2Page, setTable2Page] = useState(1);
  const [table3Page, setTable3Page] = useState(1);
  const [table1TotalPage, setTable1TotalPage] = useState(1);
  const [table2TotalPage, setTable2TotalPage] = useState(1);
  const [table3TotalPage, setTable3TotalPage] = useState(1);
  const token = localStorage.getItem("token");
  const [searchText1, setSearchText1] = useState("");
  const [searchText2, setSearchText2] = useState("");
  const [trailResumes, setTrailResumes] = useState([]); // Added this line
  const [openBatch, setOpenBatch] = React.useState(false);
  const handleOpen = () => setOpenBatch(true);
  const handleClose = () => setOpenBatch(false);
  const getDashboardData = async () => {
    setloading(true);
    try {
      const res = await getCollegeDashboardApi(token);
      setDashBoardData(res?.data?.data);
      console.log("res", res);
      setloading(false);
    } catch (error) {
      setloading(false);
    }
  };

  const getResumeCreated = async () => {
    try {
      const res = await getCollegeResumeApi(table1Page, searchText1, token);
      setTable1Data(res?.data?.data?.data);
      console.log(res);
      setTable1TotalPage(res?.data?.pages);
    } catch (error) {
      console.log(error);
    }
  };
  const getInterview = async () => {
    try {
      const res = await getCollegeInterviewApi(table2Page, searchText2, token);
      setTable2Data(res?.data?.data);
      setTable2TotalPage(res?.data?.pages);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("Fetching Dashboard Data...");
    getDashboardData();
  }, []);

  useEffect(() => {
    console.log("Fetching Resume Data...");
    getResumeCreated();
  }, [table1Page]);
  useEffect(() => {
    console.log("Fetching Interview Data...");
    getInterview();
  }, [table2Page]);

  useEffect(() => {
    getResumeCreated();
  }, [searchText1, table1Page]);
  useEffect(() => {
    getInterview();
  }, [searchText2, table2Page]);

  const viewResumesHandler = async (student) => {
    console.log("View Resumes Button Clicked!");
    console.log("Student Data:", student);
    console.log("Student ID:", student._id);
    console.log("Token:", token);

    try {
      const resumesResponse = await axios.post(
        // "https://resumatic-api.viosa.in/admin/studentResumes",
        "https://api.viosa.in/resumatic/admin/studentResumes",
        {
          userId: student._id, // Assuming user ID is present in userData
        }
      );
      console.log("API Response3", resumesResponse);
      console.log("API Response4", resumesResponse.data.data.trailResume);

      // Open the dialog
      setSelectedStudent(student);
      setTrailResumes(resumesResponse.data.data.trailResume); // Set trail resumes in state
      setOpenModal(true);
    } catch (error) {
      console.error("Error fetching user resumes:", error);
    }
  };
  const [selectedStudent, setSelectedStudent] = useState(null); // Added state for selected student
  const [openModal, setOpenModal] = useState(false);
  const closeViewResumesModal = () => {
    setSelectedStudent(null);
    setOpenModal(false);
  };
  let ResumeData = useSelector((item) => item?.resume);
  if (!!data) {
    ResumeData = data;
  }
  //   const ResumeData = resumesResponse.data.data.trailResume;
  const CoverData = useSelector((item) => item.cover);
  const [loading, setLoading] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [showPdfModal, setShowPdfModal] = useState(false);

  const handlePdfModalClose = () => setShowPdfModal(false);
  const handlePdfModalShow = () => setShowPdfModal(true);
  const downloadResume = async (resumeId, student, item) => {
    try {
      setLoading(true);
      console.log("Downloading Resume:", resumeId);
      // Fetch the specific resume data for the selected resumeId
      console.log("Resume ID:", resumeId);
      // const response = await GetTrailResumeById(resumeId);
      // console.log("Item:", item);
      console.log("Student:", student);

      console.log("Student ID:", student[0].user);

      const response = await axios.post(
        // "https://resumatic-api.viosa.in/admin/studentResumes",
        "https://api.viosa.in/resumatic/admin/studentResumes",
        {
          userId: student[0].user, // Assuming user ID is present in userData
        }
      );
      const resumeData = response.data.data.trailResume.find(
        (resume) => resume._id === resumeId
      );
      // const resumeData = response.data.data.trailResume[0]; // Adjust this based on your API response structure
      const templateId = response.data.data.trailResume[0].template;

      // Use the resume data to generate and download the PDF
      const pdfResponse = await fetch(
        // "https://resumatic-api.viosa.in/pdf/generate-pdf",

        "https://api.viosa.in/resumatic/pdf/generate-pdf",

        // "http://localhost:4000/pdf/generate-pdf",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            ResumeData: resumeData,
            Template: templateId,
            doctype: "resume",
            CoverData: CoverData,
          }),
        }
      );

      //       if (pdfResponse.ok) {
      //         // Handle the PDF response (e.g., open in a new window or provide a download link)
      //         const pdfBlob = await pdfResponse.blob();
      //         const pdfUrl = URL.createObjectURL(pdfBlob);

      //         // Example: Open PDF in a new window
      //         window.open(pdfUrl, "_blank");
      //       } else {
      //         // Handle error
      //         const errorMessage = await pdfResponse.text();
      //         console.error("Failed to generate PDF,", errorMessage);
      //       }
      //     } catch (error) {
      //       console.error("Error during PDF generation:", error);
      //     } finally {
      //       setLoading(false);
      //     }
      //   };
      if (pdfResponse.ok) {
        // const pdfBlob = await pdfResponse.blob();
        // setPdfBlob(pdfBlob);
        // setOpenModal(false);
        // handlePdfModalShow();
        const { pdfUrl } = await pdfResponse.json();
        // const pdfurl2 = "https://resumatic-api.viosa.in" + pdfUrl;
        const pdfurl2 = "https://api.viosa.in" + pdfUrl;
        console.log("PDFURL", pdfUrl);
        console.log("Full PDFURL: ", pdfurl2);
        // saveAs(`https://resumatic-api.viosa.in${pdfUrl}`, "document.pdf");
        saveAs(`https://api.viosa.in${pdfUrl}`, "document.pdf");
      } else {
        // Handle error
        const errorMessage = await pdfResponse.text();
        console.error("Failed to generate PDF,", errorMessage);
      }
    } catch (error) {
      console.error("Error during PDF generation:", error);
    } finally {
      setLoading(false);
    }
  };

  const [Gmails, setGmails] = useState(null);
  const collegeId = localStorage.getItem("userId");
  const [allBatches, setallBatches] = useState([]);
  const [batchLoading, setbatchLoading] = useState(FaLessThanEqual);
  const [batchObject, setbatchObject] = useState({
    name: "",
    collegeid: collegeId,
    studyclass: "",
    standard: "",
    year: "",
    students: [],
    interviews: [],
  });
  const handleAddStudents = (e) => {
    e.preventDefault();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(Gmails)) {
      setbatchObject({
        ...batchObject,
        students: [...batchObject.students, Gmails],
      });
    } else {
      alert("Please enter a valid Gmail address");
    }
    setGmails("");
  };

  const handleRemoveGmail = (e, index) => {
    e.preventDefault();
    const updatedGmails = [...batchObject.students];
    updatedGmails.splice(index, 1);
    setbatchObject({ ...batchObject, students: updatedGmails });
  };

  const handleChangeBatchObject = (e) => {
    const { name, value } = e.target;
    setbatchObject({ ...batchObject, [name]: value });
  };
  const handleTeamCreation = async () => {
    try {
      const res = await createTeam(batchObject);
      handleClose();
      console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCollegeTeams = async () => {
    try {
      const res = await GetTeam();
      setallBatches(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCollegeTeams();
  }, []);
  // const getResumeCreated = async () => {
  //   try {
  //     const res = await getCollegeResumeApi(1, searchText1, token, {
  //       fetchAll: true,
  //     });
  //     setTable1Data(res?.data?.data?.data); // Store all data
  //     console.log(res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // const downloadCSV = () => {
  //   if (table1Data.length === 0) {
  //     alert("No data to download.");
  //     return;
  //   }

  //   const csvRows = [];
  //   const headers = ["SrNo", "Name", "Email", "Mobile", "TotalResume"];
  //   csvRows.push(headers.join(",")); // Join headers first

  //   // Prepare rows
  //   table1Data.forEach((item, index) => {
  //     csvRows.push(
  //       [
  //         index + 1,
  //         item.name || "",
  //         item.email || "",
  //         item.phoneNumber || "",
  //         item.totalResumes || 0,
  //       ].join(",")
  //     ); // Join each row
  //   });

  //   const csvContent = convertToCSV(table1Data);
  //   const blob = new Blob([csvContent], { type: "text/csv" });
  //   const url = URL.createObjectURL(blob);

  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = "resumes_data.csv";
  //   a.click();
  // };

  // const convertToCSV = (data) => {
  //   const headers = ["Sr. No", "Name", "Email", "Mobile", "Total Resume"];

  //   const csvRows = data.map((item, index) => [
  //     index + 1,
  //     item?.name || "",
  //     item?.email || "",
  //     item?.phoneNumber || "",
  //     item?.totalResumes || 0,
  //   ]);

  //   return [
  //     headers.join(","), // Join headers first
  //     ...csvRows.map((row) => row.join(",")), // Join each row
  //   ].join("\n");
  // };

  // const fetchAllPages = async () => {
  //   let allData = [];
  //   let currentPage = 1;
  //   let totalPages = 1;

  //   while (currentPage <= totalPages) {
  //     try {
  //       const res = await getCollegeResumeApi(currentPage, searchText1, token);
  //       allData = [...allData, ...res?.data?.data?.data];
  //       totalPages = res?.data?.pages;
  //       currentPage += 1;
  //     } catch (error) {
  //       console.log(error);
  //       break;
  //     }
  //   }

  //   setTable1Data(allData);
  // };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div>
        <div className="catalogue row">
          <HeaderTop
            courses={courses}
            setCourses={setCourses}
            togglesidebar={props.togglesidebar}
            name={role === "college" ? "Dashboard" : "All Courses"}
          />
          {loadspinner ? (
            <Spinner />
          ) : (
            <>
              <div className="threecardContainer">
                <div className="threeOneCard">
                  <h3 className="headingThreeCard">
                    {/* Students Who Created Resumes */}
                    Total Students
                  </h3>
                  <h5 className="headingThreeCardSmall">
                    {dashboardData?.totalStudentWhoCreatesResume || 0}
                  </h5>
                </div>
                <div className="threeOneCard">
                  <h3 className="headingThreeCard">
                    Students Who Gave the Interview
                  </h3>
                  <h5 className="headingThreeCardSmall">
                    {dashboardData?.totalStudentWhoGivenInterview || 0}
                  </h5>
                </div>
              </div>

              <div className="collegeDashboardSearchContainer">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <h2 className="collegeDashboardSearchHeading mb-0">
                    College Batches
                  </h2>
                  <button
                    className="p-1 px-2 rounded-2 common-btn"
                    onClick={handleOpen}
                  >
                    Add batch
                  </button>
                </div>
                <input
                  onChange={(e) => setSearchText1(e.target.value)}
                  value={searchText1}
                  className="collegeDashboardSearch"
                  type="text"
                  placeholder="Search Student by name and email..."
                />
                <table className="collegeTable">
                  <thead className="collegeTableHead">
                    <th className="collegeTableH">Sr. No</th>
                    <th className="collegeTableH">Name of batch</th>
                    <th className="collegeTableH">No. of Students</th>
                    <th className="collegeTableH">View Students</th>
                    {/* <th className="collegeTableH">Action</th> */}
                  </thead>
                  <tbody className="collegeTableBody">
                    {table1Data &&
                      allBatches.length > 0 &&
                      allBatches?.map((item, index, data, student) => {
                        console.log(item);
                        return (
                          <tr className="collegeTableRow">
                            <td className="collegeTableData">{index + 1}</td>
                            <td className="collegeTableData">{item?.name}</td>
                            <td className="collegeTableData">
                              {item?.students?.length}
                            </td>
                            <td className="d-flex justify-content-center align-items-center mt-2">
                              <button
                                onClick={() => {
                                  // Navigate to the desired URL
                                  window.location.href = `/profile/college/batch/${item?._id}`;
                                }}
                                className="common-btn p-1"
                              >
                                View
                              </button>
                            </td>
                            {/* <td className="collegeTableData">
                              <button
                                onClick={() => viewResumesHandler(item)}
                              ></button>
                              <button
                                onClick={() => viewResumesHandler(item)}
                              ></button>
                            </td> */}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <Dialog open={openModal} onClose={closeViewResumesModal}>
                  <DialogTitle>{`Resumes of ${
                    selectedStudent?.name || ""
                  }`}</DialogTitle>
                  <DialogContent>
                    <table className="collegeTable">
                      <thead className="collegeTableHead">
                        <tr>
                          <th className="collegeTableH">Sr. No</th>
                          <th className="collegeTableH">Resume</th>
                          <th className="collegeTableH">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Map over the resumes of the selected student */}
                        {trailResumes?.map((resume, index, student, item) => (
                          <tr className="collegeTableRow" key={resume._id}>
                            <td className="collegeTableData">{index + 1}</td>
                            <td className="collegeTableData">
                              Resume {index + 1}
                            </td>
                            <td className="collegeTableData">
                              <button
                                onClick={() =>
                                  downloadResume(resume._id, student, item)
                                }
                              >
                                Download Resume{" "}
                                {loading && (
                                  <div className="loading-spinner-container d-flex mx-2">
                                    <FontAwesomeIcon
                                      icon={faSpinner}
                                      spin
                                      size="2x"
                                    />
                                  </div>
                                )}{" "}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeViewResumesModal}>Close</Button>
                  </DialogActions>
                </Dialog>
                {/* PDF Modal */}
                <Dialog
                  open={showPdfModal}
                  onClose={handlePdfModalClose}
                  fullWidth={true}
                  size="lg"
                  className="modal-90w"
                >
                  <DialogTitle>Resume PDF Preview</DialogTitle>
                  <DialogContent>
                    {pdfBlob && (
                      <Worker
                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                      >
                        <Viewer fileUrl={URL.createObjectURL(pdfBlob)} />
                      </Worker>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button variant="secondary" onClick={handlePdfModalClose}>
                      Close
                    </Button>
                    {pdfBlob && (
                      <Button
                        variant="primary"
                        download="resume.pdf"
                        href={URL.createObjectURL(pdfBlob)}
                      >
                        Download PDF
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
                <div className="collegePagination">
                  <Stack spacing={2}>
                    <Pagination
                      count={table3TotalPage}
                      page={table3Page}
                      onChange={(e, page) => setTable3Page(page)}
                    />
                  </Stack>
                </div>
              </div>

              <div className="collegeDashboardSearchContainer">
                <h2 className="collegeDashboardSearchHeading">Resumes</h2>
                <input
                  onChange={(e) => setSearchText1(e.target.value)}
                  value={searchText1}
                  className="collegeDashboardSearch"
                  type="text"
                  placeholder="Search Student by name and email..."
                />
                {/*<button className="common-btn p-1" onClick={downloadCSV}>
                  Download Resumes Data
                </button>
                <button className="common-btn p-1" onClick={fetchAllPages}>
                  Fetch All Data
                </button> */}
                <table className="collegeTable">
                  <thead className="collegeTableHead">
                    <th className="collegeTableH">Sr. No</th>
                    <th className="collegeTableH">Name</th>
                    <th className="collegeTableH">Email</th>
                    <th className="collegeTableH">Mobile</th>
                    <th className="collegeTableH">Total Resume</th>
                    <th className="collegeTableH">Actions</th>
                  </thead>
                  <tbody className="collegeTableBody">
                    {table1Data &&
                      table1Data.length > 0 &&
                      table1Data?.map((item, index, data, student) => {
                        {
                          /* console.log("item:", item); // Added console.log
                        console.log("student:", student); // Added console.log */
                        }
                        return (
                          <tr className="collegeTableRow">
                            <td className="collegeTableData">{index + 1}</td>
                            <td className="collegeTableData">{item?.name}</td>
                            <td className="collegeTableData">{item?.email}</td>
                            <td className="collegeTableData">
                              {item?.phoneNumber}
                            </td>
                            <td className="collegeTableData">
                              {item?.totalResumes}
                            </td>
                            <td className="collegeTableData">
                              {/* <button onClick={() => viewResumesHandler(item)}>
                                View Resumes
                              </button> */}
                              <button onClick={() => viewResumesHandler(item)}>
                                View Resumes
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <Dialog open={openModal} onClose={closeViewResumesModal}>
                  <DialogTitle>{`Resumes of ${
                    selectedStudent?.name || ""
                  }`}</DialogTitle>
                  <DialogContent>
                    <table className="collegeTable">
                      <thead className="collegeTableHead">
                        <tr>
                          <th className="collegeTableH">Sr. No</th>
                          <th className="collegeTableH">Resume</th>
                          <th className="collegeTableH">View</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* Map over the resumes of the selected student */}
                        {trailResumes?.map((resume, index, student, item) => (
                          <tr className="collegeTableRow" key={resume._id}>
                            <td className="collegeTableData">{index + 1}</td>
                            <td className="collegeTableData">
                              Resume {index + 1}
                            </td>
                            <td className="collegeTableData">
                              <button
                                onClick={() =>
                                  downloadResume(resume._id, student, item)
                                }
                              >
                                Download Resume{" "}
                                {loading && (
                                  <div className="loading-spinner-container d-flex mx-2">
                                    <FontAwesomeIcon
                                      icon={faSpinner}
                                      spin
                                      size="2x"
                                    />
                                  </div>
                                )}{" "}
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={closeViewResumesModal}>Close</Button>
                  </DialogActions>
                </Dialog>
                {/* PDF Modal */}
                <Dialog
                  open={showPdfModal}
                  onClose={handlePdfModalClose}
                  fullWidth={true}
                  size="lg"
                  className="modal-90w"
                >
                  <DialogTitle>Resume PDF Preview</DialogTitle>
                  <DialogContent>
                    {pdfBlob && (
                      <Worker
                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                      >
                        <Viewer fileUrl={URL.createObjectURL(pdfBlob)} />
                      </Worker>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button variant="secondary" onClick={handlePdfModalClose}>
                      Close
                    </Button>
                    {pdfBlob && (
                      <Button
                        variant="primary"
                        download="resume.pdf"
                        href={URL.createObjectURL(pdfBlob)}
                      >
                        Download PDF
                      </Button>
                    )}
                  </DialogActions>
                </Dialog>
                <div className="collegePagination">
                  <Stack spacing={2}>
                    <Pagination
                      count={table1TotalPage}
                      page={table1Page}
                      onChange={(e, page) => setTable1Page(page)}
                    />
                  </Stack>
                </div>
              </div>

              <div className="collegeDashboardSearchContainer">
                <h2 className="collegeDashboardSearchHeading">
                  Mock Interviews
                </h2>
                <input
                  onChange={(e) => setSearchText2(e.target.value)}
                  value={searchText2}
                  className="collegeDashboardSearch"
                  type="text"
                  placeholder="Search Student by name and email..."
                />
                <table className="collegeTable">
                  <thead className="collegeTableHead">
                    <th className="collegeTableH">S. No</th>
                    <th className="collegeTableH">Name</th>
                    <th className="collegeTableH">Email</th>
                    <th className="collegeTableH">Mobile</th>
                    <th className="collegeTableH">Job Title</th>
                    <th className="collegeTableH">Job Type</th>
                    <th className="collegeTableH">Job Category</th>
                    <th className="collegeTableH">Total Attempt</th>
                  </thead>
                  <tbody className="collegeTableBody">
                    {table2Data &&
                      table2Data.length > 0 &&
                      table2Data?.map((item, index) => {
                        return (
                          <tr className="collegeTableRow">
                            <td className="collegeTableData">{index + 1}</td>
                            <td className="collegeTableData">
                              {item?.user?.name}
                            </td>
                            <td className="collegeTableData">
                              {item?.user?.email}
                            </td>
                            <td className="collegeTableData">
                              {item?.user?.phoneNumber}
                            </td>
                            <td className="collegeTableData">
                              {item?.interview?.jobtitle}
                            </td>
                            <td className="collegeTableData">
                              {item?.interview?.jobtype}
                            </td>
                            <td className="collegeTableData">
                              {item?.interview?.jobcategory}
                            </td>
                            <td className="collegeTableData">
                              {item?.totalAttempts}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <div className="collegePagination">
                  <Stack spacing={2}>
                    <Pagination
                      count={table2TotalPage}
                      page={table2Page}
                      onChange={(e, page) => setTable2Page(page)}
                    />
                  </Stack>
                </div>
              </div>
            </>
          )}

          <Modal
            open={openBatch}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <h2
                style={{
                  fontSize: "1.3rem",
                  fontWeight: "600",
                  borderBottom: "2px solid #521986",
                }}
                className="pb-2 mb-3 text-center"
              >
                Add batches
              </h2>
              <Form>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={batchObject?.name}
                    onChange={handleChangeBatchObject}
                    placeholder="Full Name"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Study Class</Form.Label>
                  <Form.Control
                    type="text"
                    name="studyclass"
                    value={batchObject?.studyclass}
                    onChange={handleChangeBatchObject}
                    placeholder="Class"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Standard</Form.Label>
                  <Form.Control
                    type="text"
                    name="standard"
                    value={batchObject?.standard}
                    onChange={handleChangeBatchObject}
                    placeholder="Standard"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Year</Form.Label>
                  <Form.Control
                    type="number"
                    name="year"
                    value={batchObject?.year}
                    onChange={handleChangeBatchObject}
                    placeholder="Which year?"
                  />
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Add Student</Form.Label>
                  <div className="border rounded-2 p-2">
                    <div className="mb-3  d-flex flex-wrap" >
                      {
                        batchObject?.students?.map((item, index) => {
                          return <span className="border mb-1 rounded-2 p-2" >{item} <button className="bg-danger rounded p-2"><MdDelete onClick={(e) => handleRemoveGmail(e, index)} className="text-white" /></button></span>
                        })
                      }
                    </div>
                    <Form.Group className="" controlId="formBasicPassword">
                      <Form.Control type="text" placeholder="Write email id ... " value={Gmails} onChange={(e) => setGmails(e.target.value)} />
                      <button className="common-btn px-3 mt-2 p-1 rounded-2" onClick={handleAddStudents}>Add</button>
                    </Form.Group>
                  </div>
                </Form.Group> */}
              </Form>
              <div className="d-flex justify-content-center">
                <button
                  onClick={handleTeamCreation}
                  className="common-btn p-2 px-4 rounded-2 mt-3"
                >
                  Submit
                </button>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
    </motion.div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  outline: "none",
  borderRadius: "10px",
  bgcolor: "white",
  border: "3px solid #521986",
  boxShadow: 24,
  p: 4,
};

export default CollegeDashboard;
